import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/util/axios'

export const state = {
  authUser: null,
}

export const mutations = {

  LOGIN: function (state, data) {
    state.authUser = data.user
    localStorage.setItem('username', data.user.name)
    localStorage.setItem('accessToken', data.tokens.access.token)
    localStorage.setItem('refreshToken', data.tokens.refresh.token)
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.accessToken}`
  },
  LOGOUT: function (state) {
    state.authUser = null
    localStorage.removeItem('username')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    location.reload()
  },
  SET_USER: function (state, user) {
    state.authUser = user
  },
  SET_TOKEN: function (state, data){
    localStorage.setItem('accessToken', data.access.token)
    localStorage.setItem('refreshToken', data.refresh.token)
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.access.token}`
  }
}

export const actions = {
  
  async login({ commit }, { email, password }) {
    let payload = Buffer.from(JSON.stringify({email, password}), "utf8").toString('base64');
    let { data, status } = await axios.post('/api/v1/auth/login', { payload })
    
    if (status === 200)
      commit('LOGIN', data)
    else
      throw new Error('로그인에 실패했습니다.')
  },
  async logout({ commit }) {
    // await axios.post('/api/auth/logout').then(() => commit('LOGOUT'))
    commit('LOGOUT')
  },
  async refresh({ commit }) {
    let { data, status } = await axios.post('/api/v1/auth/refresh-tokens', {refreshToken: localStorage.getItem('refreshToken')})
    if (status !== 200) {
      commit('LOGOUT')
      throw new Error('Token 갱신에 실패했습니다.')
    }
    commit('SET_TOKEN', data)
  }
}

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions
})