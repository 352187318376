import axios from 'axios';
import store from '../store/index'

axios.defaults.retry = 3

// Add a request interceptor
axios.interceptors.request.use(async function (config) {
    // Do something before request is sent
    config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('accessToken');
      
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    
    return response;
  }, async function (error) {
    
    // const errorAPI = error.config;
    // console.log(error.response)
    if(error.response.status === 401) {
      // console.log(error.response)
      // if(error.response.data.message === 'Please authenticate' && error.response.config.url !== '/api/v1/auth/refresh-tokens'){
      //   await store.dispatch('refresh')
      //   // return await axios(errorAPI);
      // }
      // else {
      store.dispatch('logout');
      // }
    }
    return Promise.reject(error);
  });


export default axios;