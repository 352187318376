// VueJS
import Vue from 'vue'
import { i18n } from "./i18n";

// Ant Design Vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);

// Photoswipe Gallery
// import Photoswipe from 'vue-pswipe'
// Vue.use(Photoswipe)

// Template Layouts
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
// import DashboardRTLLayout from './layouts/DashboardRTL.vue'

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
// Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

// Main application view
import App from './App.vue'
import VueMoment from 'vue-moment'
Vue.use(VueMoment);
// Vue Router
import router from './router'
// Vue Store
import store from './store/index'
// axios
import axios from './util/axios'
Vue.prototype.$http = axios
// App Styling
import './scss/app.scss';

Vue.config.productionTip = false

// Initialize Vue
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')