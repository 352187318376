<template>
	
	<!-- Layout Footer -->
	<a-layout-footer>
		
	</a-layout-footer>
	<!-- / Layout Footer -->

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>
